import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { frontendRoutes } from '../../../Constants/constants';
import { chooseValidation } from '../../../Constants/validations';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addStaticToken,
  choose_community,
  getBuilderId,
  search_community,
} from '../../../redux/action/action';
import Loader from '../../Loader/Loader';
import { Button } from '../../Button/Button';
import { datadogLogs } from '@datadog/browser-logs';
import { BuilderSettings } from '../../../redux/types/builders';
import Swal from 'sweetalert2';
import { honeypot } from '@honeypot-run/core';


const ChooseCommunity = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ builder_name?: string }>();
  const community = useSelector((state: any) => state.community.community);
  const builderId = useSelector((state: any) => state.builder.builder_id);
  const communityLoading = useSelector((state: any) => state.community.loading);
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);
  const companyName = builderSettings?.legal_name || builderSettings?.webflow_name || builderSettings?.builderName;

  const formik = useFormik<FormikValues>({
    validateOnChange: false,
    initialValues: {
      choose: null,
      privacyPolicy: false,
      customDisclosureActive: false,
      customDisclosure: false,
    },
    validationSchema: chooseValidation,
    onSubmit: values => {
      const data = { choose: values.choose };
      const communityId = data?.choose?.community_id;

      setIsLoading(true);
      dispatch(search_community(data));
      setTimeout(() => {
        setIsLoading(false);
        honeypot.track(
					'Community Selected',
					{ communityId: communityId ,builderName: params.builder_name });
        history.push(frontendRoutes.community(params.builder_name, communityId).path);
      }, 1000);
    },
  });

  useEffect(() => {
    const formikFieldActive = formik.getFieldProps('customDisclosureActive')?.value ?? false;

    if (builderSettings?.custom_disclosure_active && !formikFieldActive) {
      formik.setFieldValue('customDisclosureActive', true);
    }
  }, [builderSettings?.custom_disclosure_active, formik]);

  useEffect(() => {
    if (!builderId) {
      dispatch(addStaticToken());
      dispatch(getBuilderId());
    }
  }, [dispatch, builderId]);

  useEffect(() => {
    dispatch(choose_community());
  }, [dispatch]);

  useEffect(() => {
    if (builderId && !communityLoading && (!community || community.length === 0)) {
      datadogLogs.logger.warn('Not found community', { community, builderId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community, communityLoading]);

  const previousPath = () => {
    history.goBack();
  };

  const options =
    community &&
    community.map((a: any) => {
      return { label: a.community.name, value: a.id, community_id: a.community.community_id };
    });

  const defaultDisclosureModal = () => {
    Swal.fire({
      title: 'Visitor Access Agreement',
      text: `Customer agrees that any entry onto the property is at the Customer’s own risk. Customer hereby waives any and all claims against ${companyName} Inc., its affiliates, subsidiaries, owners, officers, directors, managers, employees, representatives or agents (collectively, “${companyName}”) for injury or damage to person or property arising from, or related to, Customer’s entry onto the property. Customer will defend, indemnify and hold ${companyName} harmless against any injury, loss, damage or expense to person or property arising from, or related to, the acts or omissions of Customer, or of any person accompanying Customer onto the property. Customer will act in accordance with all applicable laws while on the property. Customer will not provide any false information or identification. Customer will be responsible for all damage to furniture, fixtures and equipment caused, directly or indirectly, by Customer.`,
      confirmButtonColor: builderSettings.primary_color,
      confirmButtonText: 'Accept',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        formik.setFieldValue('privacyPolicy', true);
      }

      if (result.isDismissed) {
        formik.setFieldValue('privacyPolicy', false);
      }
    });
  };

  const customDisclosureModal = () => {
    Swal.fire({
      title: builderSettings.custom_disclosure_name,
      text: builderSettings.custom_disclosure_text,
      confirmButtonColor: builderSettings.primary_color,
      confirmButtonText: 'Accept',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        formik.setFieldValue('customDisclosure', true);
      }

      if (result.isDismissed) {
        formik.setFieldValue('customDisclosure', false);
      }
    });
  };

  return (
    <section className="main-section community-screen d-flex justify-content-center">
      <div className="box">
        <Header />
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column position-relative">
          <span className="required-text">
            <sup>*</sup>required
          </span>
          <Form onSubmit={formik.handleSubmit} className="d-flex flex-column h-100">
            <FormikProvider value={formik}>
              <Form.Group controlId="community">
                <Form.Label>
                  What community would you like to tour?<sup>*</sup>
                </Form.Label>
                <Select
                  noOptionsMessage={() => 'Currently, there are no homes available for tours'}
                  options={options}
                  onChange={option => formik.setFieldValue('choose', option)}
                  value={formik.values.choose}
                  classNamePrefix="netrnow-select"
                  placeholder="Please select a community"
                />
                {formik.errors.choose ? (
                  <span className="invalid-feedback d-block">{formik.errors.choose}</span>
                ) : (
                  ''
                )}
              </Form.Group>
              <div className="box-footer mt-auto">
                <Form.Group
                  className={
                    formik.errors.privacyPolicy || formik.errors.customDisclosure
                      ? 'form-group form-group-checkbox mt-0 is-invalid'
                      : 'form-group form-group-checkbox mt-0'
                  }
                >
                  <Form.Check
                    type="checkbox"
                    label={
                      <small>
                        I have read and accept NterNow’s{' '}
                        <button
                          type="button"
                          className="link-button"
                          onClick={() => {
                            defaultDisclosureModal();
                          }}
                        >
                          Visitor Access Agreement
                        </button>
                        {', '}
                        <a
                          href="https://nternow.com/privacy-policy/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a
                          href="https://nternow.com/privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms of service
                        </a>
                        .
                      </small>
                    }
                    id="privacyPolicy"
                    checked={formik.values.privacyPolicy}
                    isInvalid={!!formik.errors.privacyPolicy}
                    onChange={() =>
                      formik.setFieldValue('privacyPolicy', !formik.values.privacyPolicy)
                    }
                  />
                  {builderSettings?.custom_disclosure_active && (
                    <Form.Check
                      type="checkbox"
                      label={
                        <small>
                          <button
                            type="button"
                            className="link-button"
                            onClick={() => {
                              customDisclosureModal();
                            }}
                          >
                            {builderSettings.custom_disclosure_name}
                          </button>
                          .
                        </small>
                      }
                      id="customDisclosure"
                      checked={formik.values.customDisclosure}
                      isInvalid={!!formik.errors.customDisclosure}
                      onChange={() =>
                        formik.setFieldValue('customDisclosure', !formik.values.customDisclosure)
                      }
                    />
                  )}
                  <span className="invalid-feedback">
                    {formik.errors.privacyPolicy || formik.errors.customDisclosure
                      ? 'You must accept our terms and conditions to continue'
                      : ''}
                  </span>
                </Form.Group>
                {isLoading && <Loader />}
                <Button className="button w-100" type="submit">
                  Continue
                </Button>
              </div>
            </FormikProvider>
          </Form>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default ChooseCommunity;

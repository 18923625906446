import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import search_image from '../../../assets/images/search-image.jpg';
import imagePlaceholder from '../../../assets/images/community-image-1.jpg';
import { backendRoutes, frontendRoutes } from '../../../Constants/constants';
import Loader from '../../Loader/Loader';
import Footer from '../../Footer/Footer';
import {
  addPropertyId,
  addStaticToken,
  getBuilderId,
  get_property_details,
  search_community,
} from '../../../redux/action/action';
import ReactPaginate from 'react-paginate';
import { honeypot } from '@honeypot-run/core';

type SelectedCommunity = {
  community_id: number;
  name: string;
  properties: number[];
};

const SearchCommunity = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [propertyList, setPropertyList] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [filterData, setFilterData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [propertyImages, setPropertyImages] = useState<string[]>([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ builder_name?: string; community_id: string }>();
  const community = useSelector((state: any) => state.community.community);
  const builderId = useSelector((state: any) => state.builder.builder_id);
  const isRental = useSelector((state: any) => state.builder.isRental);
  const [communityName, setCommunityName] = useState('');
  const [selectedCommunity, setSelectedCommunity] = useState<SelectedCommunity | null>(null);

  const userToken = localStorage.getItem('user_token');
  const excludeColumns = ['price', 'longitude', 'latitude', 'beds', 'baths', 'sqft'];
  const propertiesPerPage = 10;

  const get_properties = useCallback(id => {
    const token = localStorage.getItem('static_token');

    setIsLoading(true);
    axios({
      baseURL: backendRoutes.MOBILE_BASE_URL,
      url: `properties/${id}/info`,
      method: 'GET',
      headers: {
        'x-auth-token': token,
      },
    })
      .then(res => {
        setIsLoading(false);
        setPropertyList(arr => [...arr, { id, ...res.data }].sort((a, b) => a.id - b.id));
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const previousPath = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(addStaticToken());
  }, [dispatch]);

  useEffect(() => {
    if (!builderId) {
      dispatch(getBuilderId());
    }
  }, [builderId, dispatch]);

  useEffect(() => {
    if (!community) {
      return;
    }

    const selected = community.find(
      ({ community }: any) =>
        community.community_id === Number(params.community_id) ||
        community.name === params.community_id,
    );

    if (!selected) {
      history.push(`/${params.builder_name}`);
      return;
    }

    dispatch(
      search_community({
        choose: {
          community_id: Number(params.community_id),
        },
      }),
    );

    setSelectedCommunity({ ...selected.community });
    setCommunityName(selected.community?.name);
  }, [community, dispatch, history, params.builder_name, params.community_id]);

  useEffect(() => {
    if (!propertyList.length) {
      const startIndex = page * propertiesPerPage;
      const endIndex = startIndex + propertiesPerPage;
      const idsToLoad = selectedCommunity?.properties?.slice(startIndex, endIndex);

      setPropertyList([]);

      idsToLoad?.map((id: any) => get_properties(id));
    }
  }, [get_properties, page, propertyList.length, selectedCommunity?.properties]);

  useEffect(() => {
    searchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const handlePageClick = ({ selected }: { selected: number }) => {
    console.log('selected:', selected);
    setPage(selected);
    setPropertyList([]);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const images = propertyList.flatMap(property => {
      return property.images;
    });
    setPropertyImages(images);
  }, [propertyList]);

  useEffect(() => {
    setIsLoading(true);

    const promises = propertyImages.map(image => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = `//images.weserv.nl?url=${image}&w=380&output=webp&il`;
        img.onload = () => resolve();
        img.onerror = () => reject();
      });
    });

    Promise.all(promises)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [propertyImages]);

  const searchItems = () => {
    const lowercasedValue = searchInput.toLowerCase().trim() || searchInput;
    if (lowercasedValue === '') setFilterData(propertyList);
    else {
      const filteredData = propertyList.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key)
            ? false
            : item[key]?.toString().toLowerCase().includes(lowercasedValue),
        );
      });
      setFilterData(filteredData);
    }
  };
  const getPropertyValue = (data: any) => {
    dispatch(get_property_details(data));
    dispatch(addPropertyId(data.id));

    honeypot.track(
      'Property Selected',
      { propertyId: data.id ,address: data.lotNumber ? `Lot ${data.lotNumber}, ${data.address}` : data.address });

    userToken
      ? history.push(frontendRoutes.preferTour(params.builder_name).path)
      : history.push(frontendRoutes.signIn(params.builder_name).path);
  };

  return (
    <section className="main-section search-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title">{<h1>{communityName}</h1>}</div>
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column">
          <Form>
            <Form.Group controlId="community">
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="Search by Property ID, Address, or Lot Num"
                onChange={e => setSearchInput(e.target.value)}
              />
            </Form.Group>
          </Form>
          {isLoading && <Loader />}
          <ul className="d-flex flex-column item-list" style={{ gap: '1.5rem' }}>
            {searchInput.length > 1 ? (
              filterData.length !== 0 ? (
                filterData.map((data: any) => {
                  const price = new Intl.NumberFormat('en-US').format(data.price)
                  return (
                    <li className="item item-hide-image" key={data.id}>
                      <figure className="picture">
                        <img src={search_image} alt="Verify Identify" />
                      </figure>
                      <div className="item-data" onClick={() => getPropertyValue(data)}>
                        <span className="icn icn-back-arrow"></span>
                        {data.marketingName && <h2>{data.marketingName}</h2>}
                        <h2 className="full-address">
                          {data.lotNumber ? `Lot ${data.lotNumber}, ${data.address}` : data.address}
                        </h2>
                        <small>Property ID - {data.id}</small>
                        <span className="small">${price}{isRental ? "/month": ""}</span>
                        <ul className="d-flex small">
                          <li>
                            <i className="icn icn-bad"></i>
                            {data.beds}Bd
                          </li>
                          <li>
                            <i className="icn icn-bath"></i>
                            {data.baths}Fba
                          </li>
                          <li>
                            <i className="icn icn-bath"></i>
                            {data.hBaths}Hba
                          </li>
                          <li>
                            <i className="icn icn-feet"></i>
                            {data.sqft}Sqft
                          </li>
                        </ul>
                      </div>
                    </li>
                  );
                })
              ) : (
                <span>No Result Found !!!</span>
              )
            ) : (
              propertyList &&
              propertyList.map((data: any) => {
                const price = new Intl.NumberFormat('en-US').format(data.price)
                const images = data.images.map((original: string) => {
                  return {
                    original: `//images.weserv.nl?url=${original}&w=380&output=webp&il`,
                    originalWidth: '380px',
                    originalHeight: '380px',
                  };
                });

                return (
                  <div className="tour-card" key={data.id}>
                    {images.length > 0 && (
                      <ImageGallery
                        items={images}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        additionalClass="image-gallery-custom"
                        onErrorImageURL={imagePlaceholder}
                      />
                    )}
                    <div className="item item-hide-image p-3">
                      <div className="item-data" onClick={() => getPropertyValue(data)}>
                        <span className="icn icn-back-arrow"></span>
                        {data.marketingName && <h2>{data.marketingName}</h2>}
                        <h2 className="full-address">
                          {data.lotNumber ? `Lot ${data.lotNumber}, ${data.address}` : data.address}
                        </h2>
                        <small>Property ID - {data.id}</small>
                        <span className="small">${price}{isRental ? "/month": ""}</span>
                        <ul className="d-flex small">
                          <li>
                            <i className="icn icn-bad"></i>
                            {data.beds}Bd
                          </li>
                          <li>
                            <i className="icn icn-bath"></i>
                            {data.baths}Fba
                          </li>
                          <li>
                            <i className="icn icn-bath"></i>
                            {data.hBaths}Hba
                          </li>
                          <li>
                            <i className="icn icn-feet"></i>
                            {data.sqft}Sqft
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </ul>
          {selectedCommunity && selectedCommunity?.properties?.length > 5 && (
            <div className="pagination mt-5">
              <ReactPaginate
                breakLabel="..."
                nextLabel="&#10095;"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={selectedCommunity.properties.length / propertiesPerPage}
                forcePage={page ? page : 0}
                previousLabel="&#10094;"
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default SearchCommunity;
